import {Route, Routes} from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import {useEffect, useState} from 'react';
import MobileHome from './pages/MobileHome';

function App() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobileDevice = /android|iphone|ipad|ipod|blackberry|windows phone/.test(userAgent);
        setIsMobile(isMobileDevice);
    }, [])

    return (
    <> 
        <Routes>
          <Route path='/' element={isMobile?<MobileHome/>:<Home/>}></Route>
        </Routes>
    </>
    );
}

export default App;
