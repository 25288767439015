import React from 'react';
import "../styles/home.css"
import Footer from '../components/Footer';
import Header from '../components/Header';

const Home = () => {
    return (
        <>
        <Header></Header>
        <main id='home'>
            <section id='section-info-team'>
                <div>
                    <img src='/images/logo_white_big.png' alt='logo'></img>
                    <p>KINGMAKERS</p>
                    <p>킹메이커스를 소개합니다.</p>
                    <p>저희 팀은 "사람들이 어떤 학문을 배우고 싶어 할 때,<br/>
                        쉽게 입문할 수 있게 하기"라는<br/>
                        최종 목표를 가지고 있습니다.<br/>
                        앞으로도 여러분들이 여러 학문에<br/>
                        쉽게 입문할 수 있도록 노력하겠습니다.</p>
                </div>
            </section>

            <section id='section-info-teacher'>
                <p>KINGMAKERS</p>
                <p>수업이 끝나도 웹 페이지를 구현할 수 없으시다면<br/>
                    <span>
                        제 모든 시간을 들여서라도
                        <span>될 때까지 무료</span>로 도와드립니다.</span>
                </p>
                <div>
                    <div>
                        <div>
                            <p>대표강사</p>
                            <p>김지호</p>
                            <div></div>
                        </div>
                        <div className='container-lecture-history'>
                            <div className="lecture-history">
                                <p>하이미디어 아카데미 (국비수업)</p>
                                <p>(클라우드 기반 MSA)풀스택 자바 개발자(React,SpringBoot,AWS)</p>
                                <p>Open Api를 활용한 공공 빅데이터 자바 개발자 양성과정_육성 2회차</p>
                                <p>Open Api를 활용한 공공 빅데이터 자바 개발자 양성과정_육성 1회차</p>
                                <p>정보처리산업기사 취득과정 A</p>
                            </div>
                            <div className="lecture-history">
                                <p>코리아 IT아카데미</p>
                                <p>C, 자료구조, 알고리즘과정</p>
                            </div>
                            <div className="lecture-history">
                                <p>SBS 아카데미</p>
                                <p>웹 UI/UX과정</p>
                            </div>
                        </div>
                    </div>
                    <img src='/images/teacher_jihokim.png' alt='teacher'></img>
                </div>

            </section>

            <section id='section-info-goal'>
                <p>GOAL</p>
                <p>HTML, CSS, JS 수업 최종 목표</p>
                <p>자신이 만들고 싶은 웹 페이지를 디자인하고,<br/>
                    구현할 수 있게 해드리는 것을 최우선 목표로 하고 있습니다.<br/>
                    또한 킹메이커스는 절대로 못 따라오는 사람을 두고 가지 않습니다.<br/>
                    될 때까지 책임감을 갖고 도와드릴 것을 약속드립니다.</p>
            </section>

            <section id='section-promise'>
                <p>PROMISE</p>
                <p>수강생분들에게 드릴 수 있는 약속 ‘4가지’</p>
                <div>
                    <div className='promise-content'>
                        <p>진도를 늦추어 수강료를 더 받지 않기</p>
                        <div>
                            <p>1</p>
                            <p>학원을 등록했지만 충분히 다음 진도를 나갈 수 있음에도 수강료를 더 받기 위해 진도를 늦추거나 불필요한 이야기들을 통해 돈과 시간을
                                낭비하지 않도록 하겠습니다.</p>
                        </div>
                    </div>
                    <div className='promise-content'>
                        <p>전문성 있는 강사진</p>
                        <div>
                            <p>2</p>
                            <p>학원에 배정될 때는 좋은 강사분을 만날 수 있는지 검증하기 어렵습니다. 저희는 수업 시작 전 강사분이 어떤 분이고, 가르친 수강생분들이
                                어떤 것들을 만들었는지 반드시 알려드리도록 하겠습니다.</p>
                        </div>
                    </div>
                    <div className='promise-content'>
                        <p>학습의 명확한 목적 제시</p>
                        <div>
                            <p>3</p>
                            <p>학문을 배웠지만 막상 사용하려고 하면 어디에 사용해야 할지 모르는 수강생분들이 많습니다. 어떤 곳에 사용하고 활용할 수 있는지 명확히
                                알려드리도록 하겠습니다.</p>
                        </div>
                    </div>
                    <div className='promise-content'>
                        <p>확실한 1:1 집중 관리</p>
                        <div>
                            <p>4</p>
                            <p>어려운 부분이 있는 경우 카카오톡 등을 통해 편하게 연락하고, 답변을 받을 수 있게 하겠습니다.</p>
                        </div>
                    </div>
                </div>

            </section>

            <section id='section-contents'>
                <p>CONTENTS</p>
                <p>수강생분들이 배우는 내용</p>
                <div>
                    <div className='content'>
                        <div>
                            HTML을 통해 문서 안의 내용이 제목인지, 내용인지, 이미지인지 구조를 만들 수 있습니다.
                        </div>
                        <div>
                            <div>
                                <img src='/images/logo_html.png' alt='html'></img>
                            </div>
                            <p>HTML</p>
                        </div>
                    </div>
                    <div className='content'>
                        <div>
                            CSS를 통해 HTML 문서에 디자인을 넣어 원하는 모양과 레이아웃을 구성할 수 있습니다.
                        </div>
                        <div>
                            <div>
                                <img src='/images/logo_css.png' alt='css'></img>
                            </div>
                            <p>CSS</p>
                        </div>
                    </div>
                    <div className='content'>
                        <div>
                            JS를 통해, HTML, CSS로 만든 페이지에 슬라이드 메뉴와 같은 움직이는 요소를 구현할 수 있습니다.
                        </div>
                        <div>
                            <div>
                                <img src='/images/logo_js.png' alt='js'></img>
                            </div>
                            <p>JS</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id='section-output'>
                <p>OUTPUT</p>
                <p>수강생분들이 얻을 수 있는 결과물</p>
                <div>

                    <div className='output-content'>
                        <div>
                            <img src='/images/logo_output2.png' alt='output_logo'></img>
                        </div>
                        <div>
                            <p>포트폴리오</p>
                            <p>(웹 퍼블리셔, 프론트엔드)</p>
                        </div>
                    </div>
                    <div className='output-content'>
                        <div>
                            <img src='/images/logo_output1.png' alt='output_logo'></img>
                        </div>
                        <div>
                            <p>사이트 관리</p>
                            <p>(카페24, 아임웹, 쇼피파이)</p>
                        </div>
                    </div>

                </div>
            </section>

            <section id='section-qna'>
                <p>Q&A</p>
                <div>
                    <div className='question-box1'>
                        Q. 웹 페이지를 어느정도로 구현할 수 있게 되나요?
                    </div>
                    <p className='answer-box1'>아래는 잘 만든 수강생분들의 작품입니다.<br/>
                        아래와 비슷하거나 유튜브에 올라온 분들의 작품과 비슷한 퀄리티가 나올 것 같습니다.<br/>
                        더 많은 수강생분들의 작품은 킹메이커스! 유튜브에서 확인 가능합니다.</p>
                    <div id='container-video'>
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/siusRDK9R-g?si=7HMvIFXeh4d3cVho"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen="allowfullscreen"></iframe>
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/pV1T7-B5HjM?si=ms5pPGLG83YYCf4B"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen="allowfullscreen"></iframe>
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/D9olukcLyPM?si=6XfCBVGfgaoUn8RG"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen="allowfullscreen"></iframe>
                    </div>
                </div>

                <div>
                    <div className='question-box2'>
                        Q. 비전공자도 따라갈 수 있을까요?
                    </div>
                    <p className='answer-box1'>
                        오히려 비전공자분들이 처음에 입문하기에 가장 좋은 방법 중 하나라고 생각합니다.<br/>
                        저는 몇 년간 수업을 진행하면서 여러 비전공자분들을 대상으로<br/>
                        HTML, CSS, JS 수업을 진행했는데 대부분 모두 잘 따라오셨습니다.</p>
                </div>

                <div>
                    <div className='question-box1'>
                        Q. 개강 전에 준비해야 할 것이 있을까요?
                    </div>
                    <p className='answer-box1'>
                        예습과 같은 것들은 안 해도 괜찮습니다. 모두 수업 안에서 알려드립니다.<br/>
                        하지만 충분한 수면과 식사를 통해 최상의 컨디션을 유지하시는 것이 가장 중요합니다.<br/>
                        컨디션 관리만 잘해와 주시면 좋을 것 같습니다.</p>
                </div>
            </section>

            <section id='section-contact'>
                <p>CONTACT</p>
                <p>카카오톡상담</p>
                <div>
                    <p>수강 상담, 수강 신청은 아래 카카오톡 링크를 통해 바로 가능합니다.</p>
                    <p>그 외 궁금한 사항 모두 카카오톡을 통해 편하게 물어보세요. 좋은 하루 보내세요 :)</p>
                </div>
            </section>
        </main>
        <Footer></Footer>
        </>
    );
};

export default Home;