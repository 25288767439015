import React from 'react';

const Header = () => {
    return (
        <header style={{
            width:"100%",
            height:"60px",
            minWidth:"1160px",
            backgroundColor:"black"
        }}>
            <div style={{
                display:"flex",
                width:"1160px",
                height:"60px",
                padding:"0px 20px",
                margin:"0 auto",
                alignItems:"center",
                boxSizing:"border-box"
            }}>
                <div
                 style={{
                    display:"flex",
                    gap:"8px",
                    alignItems:"center",
                 }}>
                <img src='/images/logo_white.png' alt='logo'
                style={{
                    width:"24px",
                    height:"24px"
                }}/>
                    <p style={{
                        color:"#fff",
                        fontFamily: 'PlayfairDisplay-Bold'
                    }}>KINGMAKERS</p>
                </div>
            </div>
            
        </header>
    );
};

export default Header;