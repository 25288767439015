import React from 'react';

const Footer = () => {
    return (
        <footer style={{
            width:"100%",
            height:"240px",
            minWidth:"1160px",
            backgroundColor:"black",
        }}>
            <div style={{
                display:"flex",
                flexDirection:"column",
                gap:"4px",
                width:"1160px",
                height:"240px",
                padding:"40px 20px 0px 20px",
                margin:"0 auto",
                boxSizing:"border-box",
                color:"#818181"

            }}>
                <p>킹메이커스</p>
                <p>대표 : 김지호</p>
                <p>사업자 등록번호 : 217-96-07604</p>
                <p>통신판매업 신고번호 : 2023-서울동대문-1906</p>
                <p>주소 : 서울특별시 동대문구 답십리로 57길 3-9, 101호</p>
                <p>Email : devtiger.academy@gmail.com</p>
            </div>
        </footer>
    );
};

export default Footer;